import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import { translate } from 'translations/i18n';
import Typography from 'components/Typography';
import useIsMobile from 'hooks/useIsMobile';

import styles from './styles.module.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const isMobile = useIsMobile();

  const subscribeButtonUrl =
    'https://clapart.us14.list-manage.com/subscribe/post?u=02f7c2686f74fb0f3c7cc689b&amp;id=bae395bbe2';

  return (
    <Container>
      <Row>
        <Col className="mb-4">
          <Typography variant="h4" align="left">
            {translate('joinNewsletter.title')}
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4}>
          <Typography color="blueGray" align="left" className="m-0">
            {translate('joinNewsletter.text')}
          </Typography>
        </Col>
        <Col md={7} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 2 }}>
          <Form.Control
            className="mt-4 mt-sm-0 mb-3"
            type="email"
            id="mce-EMAIL"
            placeholder={translate('joinNewsletter.inputPlaceholder')}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Col>
        <Col md={3} lg={1} className="mt-4 mt-sm-0">
          <MailchimpSubscribe
            url={subscribeButtonUrl}
            render={({ subscribe, status }) => {
              return (
                <Button
                  type="button"
                  onClick={() => {
                    subscribe({ EMAIL: email });
                  }}
                  disabled={status !== null && status !== 'error'}
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  variant="primary"
                  className={
                    !isMobile && status !== 'success' ? styles.customButton : styles.sentButton
                  }
                >
                  {status === 'success' ? (
                    'Sent'
                  ) : (
                    <>{!isMobile ? '' : translate('joinNewsletter.btn')}</>
                  )}
                </Button>
              );
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Newsletter;
