import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const Typography = ({ variant, color, underline, bold, align, children, className, ...rest }) => {
  const getVariantStyle = (variant) => {
    switch (variant) {
      case 'h1':
        return styles.primaryHeadline;
      case 'h2':
        return styles.secondaryHeadline;
      case 'h3':
        return styles.tertiaryHeadline;
      case 'h4':
        return styles.quaternaryHeadline;
      case 'h5':
        return styles.pentaHeadline;
      case 'caption':
        return styles.caption;
      case 'detail':
        return styles.detail;
      case 'label':
        return styles.label;
      case 'body1':
        return styles.body1;
      case 'body2':
        return styles.body2;
      case 'body3':
        return styles.body3;
      case 'body4':
        return styles.body3;
      case 'body5':
        return styles.body5;
      case 'body6':
        return styles.body6;
      case 'button':
        return styles.button;
      default:
        return styles.body1;
    }
  };

  const getAlignment = (align) => {
    switch (align) {
      case 'left':
        return styles.left;
      case 'right':
        return styles.right;
      default:
        return styles.center;
    }
  };

  const getBold = (bold) => (bold ? styles.bold : '');
  const getUnderline = (underline) => (underline ? styles.underline : '');
  const getGradientColor = () => (color === 'gradient' ? styles.gradientColor : '');

  return (
    <div
      className={`
        ${getVariantStyle(variant)}
        ${getAlignment(align)} 
        ${getBold(bold)}
        ${getUnderline(underline)}
        ${getGradientColor()}
        ${className}
      `}
      style={{ color: `var(--${color})` }}
      {...rest}
    >
      {children}
    </div>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'body1',
    'body2',
    'body3',
    'body4',
    'body5',
    'body6',
    'caption',
    'button',
    'label',
    'detail',
  ]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  color: PropTypes.oneOf([
    'primaryColor',
    'babyBlue',
    'fucsia',
    'black',
    'darkBlue',
    'blueGray',
    'meta',
    'gray1',
    'gray2',
    'gray3',
    'lightBlue',
    'lightFucsia',
    'lightMeta',
    'gradient',
    'white',
    'whiteColor',
    'darkMeta',
    'gunPowder',
  ]),
  bold: PropTypes.bool,
};
Typography.defaultProps = {
  color: 'white',
  bold: false,
  underline: false,
};
export default Typography;
