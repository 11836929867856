import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Typography from 'components/Typography';
import SuspenseImg from 'components/SuspenseImage';

import styles from './styles.module.css';

const ThreeCards = (props) => {
  const { data } = props;

  return (
    <Container>
      <Row className="mt-5 g-5">
        {data?.problems?.map((problem, index) => (
          <React.Fragment key={index}>
            <Col id="card" lg={4}>
              <div className={styles.card}>
                <div>
                  <SuspenseImg src={problem.image} className={styles.image} />
                  <Typography color="lightMeta" bold className="mb-2">
                    {problem.title}
                  </Typography>
                  <Typography color="blueGray">{problem.description}</Typography>
                </div>
                <div className={styles.bottom}>
                  <Typography color="whiteColor" bold>
                    {data.secondaryTitle}
                  </Typography>
                  <Typography color="blueGray">{problem.solution}</Typography>
                </div>
              </div>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </Container>
  );
};

ThreeCards.propTypes = {
  data: PropTypes.shape({
    problems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        solution: PropTypes.string,
        image: PropTypes.any,
      }),
    ),
    secondaryTitle: PropTypes.string,
    pretitle: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};

export default ThreeCards;
