const dataChart = [
  {
    text: 'Tech Development',
    value: 37.5,
    color: '#5B468B',
  },
  {
    text: 'Ops',
    value: 18.8,
    color: '#7B5BBF',
  },
  {
    text: 'Marketing/Growth',
    value: 21.8,
    color: '#9A72E9',
  },
  {
    text: 'Liquidity',
    value: 17.2,
    color: '#9987BB',
  },
  {
    text: 'Working Capital Reserve',
    value: 4.7,
    color: '#CDBBEF',
  },
];

export default dataChart;
