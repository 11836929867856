import Mail from 'assets/icons/socialIcons/mail.svg';
import Twitter from 'assets/icons/socialIcons/twitter.svg';
import Telegram from 'assets/icons/socialIcons/telegram.svg';
import Discord from 'assets/icons/socialIcons/discord.svg';
import Linkedin from 'assets/icons/socialIcons/linkedin.svg';
import Instagram from 'assets/icons/socialIcons/instagram.svg';

const socials = [
  {
    name: 'Mail',
    icon: Mail,
    url: 'mailto:contact@clapart.com',
  },
  {
    name: 'Twitter',
    icon: Twitter,
    url: 'https://twitter.com/clapartofficial',
  },
  {
    name: 'Telegram',
    icon: Telegram,
    url: 'https://t.me/CLAPART_Announcements',
  },
  {
    name: 'Discord',
    icon: Discord,
    url: 'https://discord.gg/f8TXNsDRv7',
  },
  {
    name: 'Linkedin',
    icon: Linkedin,
    url: 'https://www.linkedin.com/company/clapartgroup/',
  },
  {
    name: 'Instagram',
    icon: Instagram,
    url: 'https://www.instagram.com/clapartofficial',
  },
];

export default socials;
