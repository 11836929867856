import React, { Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { XLg } from 'react-bootstrap-icons';

import Typography from 'components/Typography';
import { routesConfig } from 'config/routesConfig';

import styles from './styles.module.css';

const Menu = ({ handleClose, opened, theme }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const reduceOpacity = () => {
    const menuItems = document.getElementsByClassName('menuItem');
    for (let i = 0; i < menuItems.length; i++) {
      menuItems[i].style.opacity = 0.3;
    }
  };

  const increaseOpacity = () => {
    const menuItems = document.getElementsByClassName('menuItem');
    for (let i = 0; i < menuItems.length; i++) {
      menuItems[i].style.opacity = 1;
    }
  };

  const renderMenuItem = (index, item, isSubitem) => (
    <Typography
      key={index}
      onClick={item.newPage === true ? () => {
        window.open("https://" + item.url + "/", '_blank');
        handleClose();
      } : () => {
        navigate(item.url);
        handleClose();
      }
    }
      color={theme === 'dark' ? 'white' : 'black'}
      variant={isSubitem ? 'body1' : 'h5'}
      align="left"
      onMouseEnter={() => {
        reduceOpacity();
      }}
      onMouseLeave={() => {
        increaseOpacity();
      }}
      className={`${styles.menuItem} ${
        location.pathname === item.url ? styles.activeMenuItem : ''
      } menuItem ${isSubitem ? 'mb-3' : 'mb-4'} ${isSubitem ? 'mx-3' : 'mx-0'}`}
    >
      {item.title}
    </Typography>
  );

  return (
    <div
      className={`${styles.wrapper} ${theme === 'light' ? styles.lightWrapperBackground : ''} ${
        opened ? styles.opened : ''
      }`}
    >
      <Container className={styles.menu}>
        <Row className={styles.logoWrapper}>
          <Col>
            <XLg
              className={`${styles.closeIcon} ${theme === 'dark' ? styles.lightIcon : ''} `}
              onClick={handleClose}
            />
          </Col>
        </Row>
        <Row className={styles.menuList}>
          <Col className={styles.itemsContainer}>
            {routesConfig.map(
              (item, index) =>
                item.showInMenu && (
                  <Fragment key={item.title}>
                    {renderMenuItem(index, item, false)}
                    {item.children &&
                      item.children.map(
                        (subitem, index) =>
                          subitem.showInMenu && renderMenuItem(index, subitem, true),
                      )}
                  </Fragment>
                ),
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Menu;
