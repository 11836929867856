import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { translate } from 'translations/i18n';
import artistSell from 'assets/images/CD-ClapArt_800x800.png';
import collectorsBuy from 'assets/images/Musical_Note-ClapArt_800x800.png';
import bothRise from 'assets/images/Heart_800x800.png';
import managerAI from 'assets/images/AIManager.png';
import Typography from 'components/Typography';
import SuspenseImg from 'components/SuspenseImage';


import styles from './styles.module.css';

const HowItWorks = () => {
  return (
    <div className={styles.wrapper}>
      <Container className="position-relative mb-5">
        <Row>
          <Col lg={{ offset: 2, span: 8 }}>
            <Typography variant="label" color="gradient" className="mb-3">
              {translate('howItWorks.pretitle')}
            </Typography>
            <Typography variant="h1" className="mb-4">
              {translate('howItWorks.title')}
            </Typography>
            <Typography color="blueGray">{translate('howItWorks.text')}</Typography>
          </Col>
        </Row>
      </Container>
      <div className={styles.backgroundWrapper}>
        <Container>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={3} className={styles.columnArtist}>
                  <SuspenseImg src={artistSell} className="mb-4 bounce h-auto" />
                  <Typography variant="h3" color="lightBlue" className="mb-4 w-75 mx-auto">
                    {translate('howItWorks.artist.firstArtistTtitle')}
                  </Typography>
                  <Typography color="blueGray">
                    {translate('howItWorks.artist.firstArtistText')}
                  </Typography>
                </Col>
                <Col sm={3} className={styles.columnArtist}>
                  <SuspenseImg src={collectorsBuy} className="mb-4 bounce h-auto" />
                  <Typography variant="h3" color="lightBlue" className="mb-4 w-75 mx-auto">
                    {translate('howItWorks.artist.secondArtistTitle')}
                  </Typography>
                  <Typography color="blueGray">
                    {translate('howItWorks.artist.secondArtistText')}
                  </Typography>
                </Col>
                <Col sm={3} className={styles.columnArtist}>
                  <SuspenseImg src={bothRise} className="mb-4 bounce h-auto" />
                  <Typography variant="h3" color="lightBlue" className="mb-4 w-75 mx-auto">
                    {translate('howItWorks.artist.thirdArtistTitle')}
                  </Typography>
                  <Typography color="blueGray">
                    {translate('howItWorks.artist.thirdArtistText')}
                  </Typography>
                </Col>
                <Col sm={3} className={styles.columnArtist}>
                  <SuspenseImg src={managerAI} className="mb-4 bounce h-auto" />
                  <Typography variant="h3" color="lightBlue" className="mb-4 w-75 mx-auto">
                    {translate('howItWorks.artist.fourthArtistTitle')}
                  </Typography>
                  <Typography color="blueGray">
                    {translate('howItWorks.artist.fourthArtistText')}
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HowItWorks;
