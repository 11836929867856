import { translate } from 'translations/i18n';

export const cards = [
  {
    reward: translate('presale.tokenRewards.firstReward'),
    interval: translate('presale.tokenRewards.firstInterval'),
    recommended: false,
  },
  {
    reward: translate('presale.tokenRewards.secondReward'),
    interval: translate('presale.tokenRewards.secondInterval'),
    recommended: false,
  },
  {
    reward: translate('presale.tokenRewards.thirdReward'),
    interval: translate('presale.tokenRewards.thirdInterval'),
    recommended: true,
  },
  {
    reward: translate('presale.tokenRewards.fourthReward'),
    interval: translate('presale.tokenRewards.fourthInterval'),
    recommended: false,
  },
  {
    reward: translate('presale.tokenRewards.fifthReward'),
    interval: translate('presale.tokenRewards.fifthInterval'),
    recommended: false,
  },
  {
    reward: translate('presale.tokenRewards.sixthReward'),
    interval: translate('presale.tokenRewards.sixthInterval'),
    recommended: false,
  },
];

export const splitProps = {
  leftLabel: translate('presale.tokenRewards.leftLabel'),
  leftValue: translate('presale.tokenRewards.leftValue'),
  rightLabel: translate('presale.tokenRewards.rightLabel'),
  rightValue: translate('presale.tokenRewards.rightValue'),
  buttonText: translate('presale.tokenRewards.buttonText'),
};
