import { Fragment } from 'react';
import SectionSpacing from 'components/SectionSpacing';

const renderPageSections = (sections, sectionSpacing) => {
  return sections.map((section, index) => (
    <Fragment key={`section_${index}`}>
      {section}
      <SectionSpacing height={sectionSpacing} />
    </Fragment>
  ));
};

export default renderPageSections;
