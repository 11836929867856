import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Typography from 'components/Typography';
import Chart from 'components/Chart';
import { translate } from 'translations/i18n';
import useChart from './dataChart';

import styles from './styles.module.css';

const UseOfFunds = () => {
  return (
    <Container className="position-relative">
      <div className={styles.firstBackgroundGradient} />
      <div className={styles.secondBackgroundGradient} />
      <Row>
        <Col xs={12} className="text-center mb-3">
          <Typography variant="caption" color="gradient">
            {translate('presale.useOfFunds.pretitle')}
          </Typography>
        </Col>
        <Col xs={12} className="text-center mb-3">
          <Typography variant="h1">{translate('presale.useOfFunds.title')}</Typography>
        </Col>
        <Col xs={12} md={{ offset: 3, span: 6 }} className="text-center mb-5 mt-3">
          <Typography variant="body1" color="blueGray">
            {translate('presale.useOfFunds.text')}
          </Typography>
        </Col>
        <Chart dataChart={useChart} />
      </Row>
    </Container>
  );
};

export default UseOfFunds;
