import { translate } from 'translations/i18n';

const dataChart = [
  {
    text: translate('presale.chartPie.sale'),
    value: 13.18,
    color: '#664e9b',
  },
  {
    text: translate('presale.chartPie.foundersTeam'),
    value: 11.56,
    color: '#7B5BBF',
  },
  {
    text: translate('presale.chartPie.advisors'),
    value: 4.9,
    color: '#9A72E9',
  },
  {
    text: translate('presale.chartPie.airdrop'),
    value: 2.7,
    color: '#8f7ab5',
  },
  {
    text: translate('presale.chartPie.marketing'),
    value: 10.83,
    color: '#9384b0',
  },
  {
    text: translate('presale.chartPie.artist'),
    value: 8.75,
    color: '#c9abff',
  },
  {
    text: translate('presale.chartPie.ecosystem'),
    value: 10.83,
    color: '#bda6ec',
  },
  {
    text: translate('presale.chartPie.liquidityMiningReserves'),
    value: 13.16,
    color: '#cfb9fb',
  },
  {
    text: translate('presale.chartPie.staking'),
    value: 24.08,
    color: '#45378B',
  },
];

export default dataChart;
