import i18n from 'i18n-js';
import { en } from './en';

i18n.translations = { en };
i18n.fallbacks = false;

const defaultLang = { languageTag: 'en' };
i18n.locale = defaultLang.languageTag;

export function translate(name, params = {}) {
  return i18n.t(name, params);
}
