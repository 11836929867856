import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { translate } from 'translations/i18n';
import Typography from 'components/Typography';
import ThreeCards from 'components/ThreeCards';
import theProblems from './problems';

const TheProblem = () => {
  return (
    <Container>
      <Row>
        <Col lg={12} className="justify-content-center text-center">
          <Typography variant="label" color="gradient">
            {translate('theProblem.pretitle')}
          </Typography>
          <Typography variant="h1" className="mb-4">
            {translate('theProblem.title')}
          </Typography>
          <Typography color="blueGray" className="mb-4 px-4">
            {translate('theProblem.text')}
          </Typography>
        </Col>
      </Row>
      <div>
        <ThreeCards data={theProblems} />
      </div>
    </Container>
  );
};

export default TheProblem;
