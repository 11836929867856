import { translate } from 'translations/i18n';

const discord = 'https://discord.com/invite/f8TXNsDRv7';
const telegram = 'https://t.me/CLAPART_Announcements';

export const presaleProcessSteps = [
  {
    title: translate('presale.presaleProcess.step1Title'),
    content1: translate('presale.presaleProcess.step1Text1'),
    partner: '(CryptoCoin.pro)',
    content2: translate('presale.presaleProcess.step1Text2'),
  },
  {
    title: translate('presale.presaleProcess.step2Title'),
    content: translate('presale.presaleProcess.step2Text'),
  },
  {
    title: translate('presale.presaleProcess.step3Title'),
    content: translate('presale.presaleProcess.step3Text'),
  },
  {
    title: translate('presale.presaleProcess.step4Title'),
    content: translate('presale.presaleProcess.step4Text'),
  },
  {
    title: translate('presale.presaleProcess.step5Title'),
    content: translate('presale.presaleProcess.step5Text'),
  },
  {
    title: translate('presale.presaleProcess.step6Title'),
    content: translate('presale.presaleProcess.step6Text'),
  },
  {
    title: translate('presale.presaleProcess.step7Title'),
    content: `${translate('presale.presaleProcess.step7Text1')}
      <a href=${discord} target="_blank">Discord</a>
    or <a href=${telegram} target="_blank">Telegram</a> ${translate(
      'presale.presaleProcess.step7Text2',
    )}`,
  },
];
