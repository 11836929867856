import React, {useEffect, useState} from "react";import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import heroImage1 from 'assets/images/presalesHero1.png';
import heroImage2 from 'assets/images/presalesHero2.png';
import tokenomics from 'assets/CLAPARTTokenomics.pdf';
import Typography from 'components/Typography';
import SuspenseImg from 'components/SuspenseImage';
import { translate } from 'translations/i18n';
import { routes } from 'config/routesConfig';

import styles from './styles.module.css';
import getPitchdeck from "../../../../helpers/getPitchdeck";

const Hero = () => {
    const navigate = useNavigate();

    const [pitchdeck, setPitchdeck] = useState();


    useEffect(() => {
        const fetchPitchdeck = async () => {
            try {
                const res = await getPitchdeck();
                setPitchdeck(res);
            } catch (error) {
                console.error("Failed to get the pitchdeck URL", error);
            }
        };

        fetchPitchdeck();
    }, [navigate]);

    return (
        <Container fluid className="p-0">
            <Container className={styles.wrapper}>
                <Container>
                    <Row>
                        <Col
                            className="position-relative text-end bounce"
                            xs={{ span: 11, order: 1 }}
                            sm={{ span: 6, order: 2 }}
                        >
                            <div className={styles.imagesBackground} />
                            <div className={styles.imagesWrapper}>
                                <SuspenseImg src={heroImage1} className={styles.image1} />
                                <SuspenseImg src={heroImage2} className={styles.image2} />
                            </div>
                        </Col>
                        <Col xs={{ span: 11, order: 2 }} sm={{ span: 6, order: 1 }}>
                            <Row>
                                <Col
                                    xs={{ span: 6, order: 2 }}
                                    sm={{ span: 6, order: 1 }}
                                    style={{ textAlign: 'left' }}
                                >
                                    {/*<Typography variant="caption" color="gradient" className="mb-3" align="left">*/}
                                    {/*    {translate('pitchdeck.title')} <br />{' '}*/}
                                    {/*</Typography>*/}
                                </Col>
                            </Row>
                            <Typography variant="h1" align="left" className="mb-4">
                                {translate('pitchdeck.pitchTitle')}
                            </Typography>
                            <Typography color="blueGray" align="left" className="mb-5">
                                {translate('pitchdeck.pitchDescription')}
                            </Typography>
                            <div className={styles.buttonsContainer}>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        window.open(pitchdeck, '_blank');
                                    }}
                                >
                                    {translate('pitchdeck.downloadPitchdeck')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Container>
    );
};

export default Hero;
