import React from 'react';
import { useLocation } from 'react-router-dom';
import { ArrowRightShort, X } from 'react-bootstrap-icons';

import Typography from 'components/Typography';
import discordIcon from 'assets/icons/discordPurple.svg';
import useIsMobile from 'hooks/useIsMobile';
import SuspenseImg from 'components/SuspenseImage';
import socials from 'components/Footer/socials';
import { routes } from 'config/routesConfig';

import styles from './styles.module.css';

const FloatingBar = ({ handleClose, theme }) => {
  const isMobile = useIsMobile();
  const isBelow500 = useIsMobile(500);

  const location = useLocation();

  return (
    <div
      className={`${styles.wrapper} ${theme === 'light' ? styles.lightMode : ''} ${
        location.pathname === routes.blog && isBelow500 && styles.alignLeft
      }`}
    >
      <div className={styles.innerWrapper}>
        <SuspenseImg src={discordIcon} className={styles.discordIcon} />
        {!isMobile && (
          <Typography
            className={styles.description}
            color={theme === 'light' ? 'darkMeta' : 'white'}
          >
            Don't miss out! Join our community and stay tuned to the project’s updates.
          </Typography>
        )}
        <div className="pointer" onClick={() => window.open(socials[3].url, '_blank')}>
          <Typography color={theme === 'light' ? 'gradient' : 'white'} variant="caption">
            JOIN DISCORD
          </Typography>
          <ArrowRightShort size={20} className={styles.rightIcon} />
        </div>
        <X size={25} className={styles.closeBtn} onClick={handleClose} />
      </div>
    </div>
  );
};

export default FloatingBar;
