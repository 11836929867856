import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { translate } from 'translations/i18n';
import Typography from 'components/Typography';

import styles from './styles.module.css';

const OurVision = () => {
  return (
    <Container className={styles.wrapper}>
      <Row>
        <Col lg={{ offset: 1, span: 10 }}>
          <Typography variant="label" color="gradient" className="mb-3">
            {translate('ourVision.pretitle')}
          </Typography>
          <Typography variant="h1" className="mb-5">
            {translate('ourVision.title')}
          </Typography>
        </Col>
        <Col lg={{ offset: 1, span: 10 }}>
          <Typography color="blueGray">{translate('ourVision.text')}</Typography>
        </Col>
      </Row>
    </Container>
  );
};

export default OurVision;
