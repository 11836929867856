import React from 'react';
import { Container } from 'react-bootstrap';

import DescriptiveBox from 'components/DescriptiveBox';
import benefits from './benefitsProps';

const UltimateBenefits = () => {
  return (
    <Container>
      <DescriptiveBox data={benefits} />
    </Container>
  );
};

export default UltimateBenefits;
