import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';

import { translate } from 'translations/i18n';
import Logo from 'assets/images/logo.svg';
import Typography from 'components/Typography';
import { routes, routesConfig } from 'config/routesConfig';
import socials from './socials';
import useIsMobile from 'hooks/useIsMobile';

import styles from './styles.module.css';

const presskitUrl = 'https://www.dropbox.com/sh/kcc3cmq8u0cwrdf/AADv6o9S6zIEaUtJirT7gri0a?dl=0';

const Footer = () => {
  const navigate = useNavigate();

  const isMobile = useIsMobile();
  const isDesktop = useIsMobile(1400);

  return (
    <footer>
      <Container fluid className={styles.footerContainer}>
        <Row className={styles.footerHeader}>
          <Col md={6} xs={12} className="mb-4">
            <Typography align="left" className={styles.footerTitle}>
              {translate('footer.title')}
            </Typography>
            <Typography variant="body4" align="left" className={styles.footerDescription}>
              {translate('footer.description')}
            </Typography>
          </Col>
          <Col
            md={6}
            xs={12}
            className={isMobile ? 'mb-4' : 'd-flex aling-items-center justify-content-end mb-4'}
          >
            <div className={styles.footerIcons}>
              {socials.map((social, index) => (
                <img
                  src={social.icon}
                  alt={social.name}
                  key={index}
                  className={styles.socialIcons}
                  onClick={() => {
                    window.open(social.url, '_blank');
                  }}
                />
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xxl={6} xs={12}>
            <img src={Logo} alt="logo" />
            <Typography variant="body4" align="left" className={styles.footerAdress}>
              {/*<br />*/}
              {/*{translate('footer.londonAddress')}*/}
              <br />
              {translate('footer.bucharestAddress')}
            </Typography>
            {/*<div className={styles.whiteButton} onClick={() => window.open(presskitUrl, '_blank')}>*/}
            {/*  <Download size={17} className={styles.downloadIcon} />*/}
            {/*  {translate('footer.pressKit')}*/}
            {/*</div>*/}
          </Col>
          <Col xxl={6} xs={12} className={isDesktop ? 'mb-4' : 'd-flex justify-content-end'}>
            <div className={styles.footerMenu}>
              {routesConfig.map(
                (route, index) =>
                  route.showInFooter && (
                    <div className={styles.menuRow} key={index}>
                      <div className={styles.menuTitle}>
                        {
                          <Link to={route.url}>
                            {
                              <Typography variant="body1" bold align="left">
                                {route.title}
                              </Typography>
                            }
                          </Link>
                        }
                      </div>
                      <div className={styles.menuSubpages}>
                        {route.children?.map(
                          (child, index) =>
                            child.showInFooter && (
                              <div
                                key={index}
                                onClick={() => {
                                  navigate(child.url);
                                }}
                              >
                                <Typography variant="body6" align="left">
                                  {child.title}
                                </Typography>
                              </div>
                            ),
                        )}
                      </div>
                    </div>
                  ),
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <div className={styles.footerFootnote}>
            <Typography variant="body4" align="left">
              {translate('footer.copyrights')}
            </Typography>
            <div className={styles.bar}>|</div>
            <Link to={routes.privacyPolicy} className="pointer">
              <Typography variant="body4" align="left">
                {translate('footer.option5')}
              </Typography>
            </Link>
            <div className={styles.bar}>|</div>
            <Link to={routes.termsOfUse} className="pointer">
              <Typography variant="body4" align="left">
                {translate('header.termsOfUse')}
              </Typography>
            </Link>
            <div className={styles.bar}>|</div>
            <Link to={routes.cookiePolicy} className="pointer">
              <Typography variant="body4" align="left">
                {translate('footer.option3')}
              </Typography>
            </Link>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      variant: PropTypes.string.isRequired,
      showInMenu: PropTypes.bool,
      showInFooter: PropTypes.bool,
    }),
  ),
};

export default Footer;
