import { createContext, useContext } from 'react';
import useBlog from 'hooks/useBlog';

export const BlogContext = createContext({});

export const BlogProvider = ({ children }) => {
  const value = useBlog();

  return <BlogContext.Provider value={value}>{children}</BlogContext.Provider>;
};

export const useBlogContext = () => {
  return useContext(BlogContext);
};
