import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import { translate } from 'translations/i18n';
import boardOfStars1 from 'assets/images/artists/Anda_Adam-ClapArt_350x500.jpg';
import boardOfStars2 from 'assets/images/artists/Amna-ClapArt_350x500.jpg';
import boardOfStars3 from 'assets/images/artists/Andrei_Stefanescu-Kamara-ClapArt_350x500.jpg';
import boardOfStars4 from 'assets/images/artists/Antonio_Pican-ClapArt_350x500.jpg';
import boardOfStars5 from 'assets/images/artists/Catalina_Ponor-ClapArt_350x500.jpg';
import boardOfStars6 from 'assets/images/artists/Connect-r-ClapArt_350x500.jpg';
import boardOfStars7 from 'assets/images/artists/Doddy-ClapArt_350x500.jpg';
import boardOfStars8 from 'assets/images/artists/Geani_Olaru-ClapArt_350x500.jpg';
import boardOfStars9 from 'assets/images/artists/Georghe_Zamfir-ClapArt_350x500.jpg';
import boardOfStars10 from 'assets/images/artists/Johny_Romano-ClapArt_350x500.jpg';
import boardOfStars11 from 'assets/images/artists/Lora-ClapArt_350x500.jpg';
import boardOfStars12 from 'assets/images/artists/Mihai_Georgescu-ClapArt_350x500.jpg';
import boardOfStars13 from 'assets/images/artists/Shift-ClapArt_350x500.jpg';
import boardOfStars14 from 'assets/images/artists/Smiley-ClapArt_350x500.jpg';
import boardOfStars15 from 'assets/images/artists/Speak-ClapArt_350x500.jpg';
import boardOfStars16 from 'assets/images/artists/Stefania-ClapArt_350x500.jpg';
import boardOfStars17 from 'assets/images/artists/SheVa-ClapAr_350x500.jpg';
import Typography from 'components/Typography';
import SuspenseImg from 'components/SuspenseImage';

import styles from './styles.module.css';
import 'swiper/css';

const stars = [
  { image: boardOfStars1, name: 'Anda Adam', country: 'ROMANIA' },
  { image: boardOfStars2, name: 'Amna', country: 'ROMANIA' },
  { image: boardOfStars3, name: 'Andrei Stefanescu & Kamara', country: 'ROMANIA' },
  { image: boardOfStars4, name: 'Antonio Pican', country: 'ROMANIA' },
  { image: boardOfStars5, name: 'Catalina Ponor', country: 'ROMANIA' },
  { image: boardOfStars6, name: 'Connect-R', country: 'ROMANIA' },
  { image: boardOfStars7, name: 'Doddy', country: 'ROMANIA' },
  { image: boardOfStars8, name: 'Geani Olaru', country: 'ROMANIA' },
  { image: boardOfStars9, name: 'Gheorghe Zamfir', country: 'ROMANIA' },
  { image: boardOfStars10, name: 'Johny Romano', country: 'ROMANIA' },
  { image: boardOfStars11, name: 'Lora', country: 'ROMANIA' },
  { image: boardOfStars12, name: 'Mihai Georgescu', country: 'ROMANIA' },
  { image: boardOfStars13, name: 'Shift', country: 'ROMANIA' },
  // { image: boardOfStars14, name: 'Smiley', country: 'ROMANIA' },
  { image: boardOfStars15, name: 'Speak', country: 'ROMANIA' },
  { image: boardOfStars16, name: 'Stefania', country: 'ROMANIA' },
  { image: boardOfStars17, name: 'SheVa', country: 'Ukraine' },

];

const BoardOfStars = () => {
  const renderCarousel = (items, isRtl) => (
    <Swiper
      className={!isRtl ? styles.swiper : ''}
      spaceBetween={10}
      breakpoints={{
        0: { slidesPerView: 2 },
        768: { slidesPerView: 6 },
        1024: { slidesPerView: 8 },
      }}
      noSwipingSelector="div.slide-wrapper"
      speed={8000}
      loop={true}
      autoplay={{
        delay: 0,
        disableOnInteraction: false,
        reverseDirection: isRtl,
      }}
      modules={[Autoplay]}
    >
      {items.map((item, index) => (
        <SwiperSlide
          onMouseEnter={(e) => {
            e.currentTarget.classList.add(styles.hovered);
          }}
          onMouseLeave={(e) => {
            e.currentTarget.classList.remove(styles.hovered);
          }}
          key={index}
        >
          <div translate="no" className={`slide-wrapper ${styles.slideWrapper}`}>
            <SuspenseImg className={styles.slideImage} src={item.image} />
            <Typography className={styles.imageText}>{item.name}</Typography>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );

  return (
    <div className={styles.wrapper}>
      <Container className="position-relative text-center d-flex d-sm-block flex-column">
        <Row className={styles.indexMax}>
          <Col xs={{ offset: 0, span: 12 }} xl={{ offset: 3, span: 6 }}>
            <Typography variant="label" color="gradient" className="mb-3" id="ambassadors">
              {translate('boardOfStars.pretitle')}
            </Typography>
            <Typography variant="h1" className="mb-5">
              {translate('boardOfStars.title')}
            </Typography>
          </Col>
          <Col xs={{ offset: 0, span: 12 }}>
            <Typography color="blueGray" className="mb-4">
              {translate('boardOfStars.text')}
            </Typography>
          </Col>
        </Row>
      </Container>
      <Container fluid className="fullWidthContainer">
        {renderCarousel(stars.slice(0, 8))}
        {renderCarousel(stars.slice(8, 16), true)}
      </Container>
    </div>
  );
};

export default BoardOfStars;
