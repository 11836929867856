import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';

const TwoColumnText = ({ firstColumn, secondColumn }) => {
  return (
    <Container>
      <Row>
        <Col
          xs={12}
          md={6}
          dangerouslySetInnerHTML={{
            __html: firstColumn,
          }}
          className="text-start"
        />
        <Col
          xs={12}
          md={6}
          dangerouslySetInnerHTML={{
            __html: secondColumn,
          }}
          className="text-start"
        />
      </Row>
    </Container>
  );
};

TwoColumnText.propTypes = {
  firstColumn: PropTypes.string,
  secondColumn: PropTypes.string,
};

export default TwoColumnText;
