import axios from 'axios';

const baseUrl = process.env.REACT_APP_STORYBLOK_URL;
const token = process.env.REACT_APP_STORYBLOK_KEY;
const version = process.env.REACT_APP_STORYBLOK_VERSION;
const pitchdeckUrl = `${baseUrl}?token=${token}&version=${version}&by_slugs=pitchdeck/*`;

const getNameByLanguageKey = (languageKey) => {
  switch (languageKey) {
    case 'en-US':
      return 'English';
    case 'ro':
      return 'Romania';
    case 'es-ES':
      return 'Spain';
    case 'tr':
      return 'Turkey';
    default:
      return 'English';
  }
};

const getPitchdeck = async () => {
  try {
    const pitchdesk = await axios.get(pitchdeckUrl);

    console.log("Selected pitchdeck", pitchdesk)

    const languageKey = localStorage.getItem('mw-active-current-locale');

    const languageName = getNameByLanguageKey(languageKey);

    const {
      data: { stories },
    } = pitchdesk;


    console.log("Selected data", pitchdesk)
    const selectedStory = stories?.find((st) => st?.name === languageName);
    console.log("Selected Story", selectedStory)
    const file = selectedStory?.content?.file?.filename;

    console.log("Selected file", file)

    return file;
  } catch (e) {
    throw new Error(e);
  }
};

export default getPitchdeck;
