import { useLocation } from 'react-router-dom';

import App from './App';

const StoryblokApp = () => {
  const location = useLocation();
  const { pathname } = location;

  return <App key={pathname} pathname={pathname} />;
};

export default StoryblokApp;
