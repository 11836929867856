import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Typography from 'components/Typography';
import Chart from 'components/Chart';
import { translate } from 'translations/i18n';
import dataChart from './dataChart';

import styles from './styles.module.css';

const TokenGenerationDistribution = () => {
  return (
    <Container className="position-relative">
      <div className={styles.firstBackgroundGradient} />
      <div className={styles.secondBackgroundGradient} />
      <Row className="mb-5">
        <Col xs={12} className="text-center mb-3">
          <Typography variant="caption" color="gradient" id="tokenomics">
            {translate('presale.chartPie.coloredTitle')}
          </Typography>
        </Col>
        <Col xs={12} className="text-center mb-3">
          <Typography variant="h1">{translate('presale.chartPie.title')}</Typography>
        </Col>
        <Col xs={12} md={{ offset: 1, span: 10 }} className="text-center mb-5 mt-3">
          <Typography variant="body1" color="blueGray">
            {translate('presale.chartPie.description')}
          </Typography>
        </Col>
        <Chart dataChart={dataChart} />
      </Row>
    </Container>
  );
};

export default TokenGenerationDistribution;
