import React from 'react';

import { translate } from 'translations/i18n';

export const financingTimelineData = [
  {
    title: 'Private Round',
    active: false,
    content: <>{translate('presale.financingTimeline.privateRound')}</>,
  },
  {
    pretitle: 'Q2-Q4 2022',
    title: 'Presale',
    active: true,
    content: <>{translate('presale.financingTimeline.presale')}</>,
  },
  {
    pretitle: 'Q1 2023',
    title: 'Exchange listings',
    active: true,
    content: <>{translate('presale.financingTimeline.exchangeListings')}</>,
  },
  {
    pretitle: 'Q2 2023',
    title: 'Public sale',
    active: true,
    content: <>{translate('presale.financingTimeline.publicSale')}</>,
  },
];
