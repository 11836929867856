import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { translate } from 'translations/i18n';
import SuspenseImg from 'components/SuspenseImage';

// flags
import gb from 'assets/icons/flags/GB.svg';
import ro from 'assets/icons/flags/RO.svg';
import tr from 'assets/icons/flags/TR.svg';
import es from 'assets/icons/flags/ES.svg';

import styles from './styles.module.css';

const LanguageSwitcher = ({ theme }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en-US');
  const [flagIcon, setFlagIcon] = useState(gb);

  useEffect(() => {
    const lsLanguage = localStorage.getItem('mw-active-current-locale');
    if (lsLanguage) {
      setSelectedLanguage(lsLanguage);
    }
  }, []);

  useEffect(() => {
    switch (selectedLanguage) {
      case 'en-US':
        setFlagIcon(gb);
        break;
      case 'ro':
        setFlagIcon(ro);
        break;
      case 'es-ES':
        setFlagIcon(es);
        break;
      case 'tr':
        setFlagIcon(tr);
        break;
      default:
        setFlagIcon(gb);
        break;
    }
  }, [selectedLanguage]);

  return (
    <div className={styles.selectWrapper} translate="no">
      <SuspenseImg src={flagIcon} className={styles.globeIcon} />
      <Form.Select
        value={selectedLanguage}
        className={`${styles.select} ${theme === 'light' ? styles.lightMode : ''} dropdown`}
        onChange={(e) => {
          setSelectedLanguage(e.target.value);
          localStorage.setItem('mw-active-current-locale', e.target.value);
          window.location.replace(`?locale=${e.target.value}`);
        }}
      >
        <option value="en-US">{translate('header.en')}</option>
        <option value="ro">{translate('header.ro')}</option>
        <option value="tr">{translate('header.tr')}</option>
        {/*<option value="hu">{translate('header.hu')}</option>*/}
        <option value="es-ES">{translate('header.es')}</option>
      </Form.Select>
    </div>
  );
};

export default LanguageSwitcher;
