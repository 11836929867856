import React, {Fragment, useEffect, useState} from "react";
import Hero from "./section/Hero/";
import renderPageSections from "../../helpers/renderPageSections";
import Video from "../Homepage/sections/Video";
import {useLocation, useNavigate} from "react-router-dom";

const sections = [
    <Hero />
];

const Pitchdeck = () => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window?.scrollTo({ top: 0 });

    }, [navigate, location]);

    return <div>{renderPageSections(sections)}</div>;
};

export default Pitchdeck;