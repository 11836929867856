import React from 'react';
import { Container } from 'react-bootstrap';

import Typography from 'components/Typography';
import AnimatedWaves from 'components/AnimatedWaves';
import theRoyaltyShareData from './theRoyaltyShareData';

import styles from './styles.module.css';

const TheRoyaltyShare = () => {
  return (
    <Container fluid>
      <Container>
        <div className={styles.royaltyCardContainer}>
          <div className={styles.royaltyCard}>
            <img
              src={theRoyaltyShareData?.image}
              alt="royaltyShare"
              className={styles.royaltyShareImg}
            />
            <div className={styles.royaltyShareContent}>
              <Typography
                variant="h2"
                color="lightMeta"
                align="left"
                className={styles.royaltyShareTitle}
              >
                {theRoyaltyShareData.title}
              </Typography>
              <Typography color="blueGray" align="left">
                {theRoyaltyShareData.text}
              </Typography>
            </div>
          </div>
        </div>
      </Container>
      <AnimatedWaves />
    </Container>
  );
};

export default TheRoyaltyShare;
