import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { translate } from 'translations/i18n';
import Typography from 'components/Typography';
import Timeline from 'components/Timeline';
import { roadmapItems } from './roadmapItems';

import styles from './styles.module.css';

const Roadmap = () => {
  return (
    <>
      <Container className={styles.wrapper}>
        <Row className="align-items-end">
          <Col sm={12} className="text-center">
            <Typography variant="label" color="gradient" className="mb-3 mb-sm-4">
              Q2 2021 - 2024
            </Typography>
            <Typography variant="h1" className="mb-4">
              {translate('roadmap.pageTitle')}
            </Typography>
          </Col>
        </Row>
      </Container>
      <Timeline id="roadmap" items={roadmapItems} />
    </>
  );
};

export default Roadmap;
