import React from 'react';
import { Container } from 'react-bootstrap';

import TwoColumnText from 'components/TwoColumnText';
import riskDisclaimerProps from 'pages/Tokenomics/sections/RiskDisclaimer/riskDisclaimerProps';
import Typography from 'components/Typography';
import { translate } from 'translations/i18n';

import styles from './styles.module.css';
import stars from 'assets/images/stars.png';

const RiskDisclaimer = () => {
  return (
    <Container className={styles.wrapper}>
      <div className={styles.firstBackgroundGradient} />
      <div className={styles.secondBackgroundGradient} />
      <div className="mb-5">
        <img alt="last_call" src={stars} />
      </div>
      <Typography variant="caption" color="gradient" className="mb-3">
        {translate('presale.riskAndDisclaimer.pretitle')}
      </Typography>
      <Typography variant="h1" className="mb-5">
        {translate('presale.riskAndDisclaimer.title')}
      </Typography>
      <Typography color="blueGray" className={styles.contentSpacing}>
        {translate('presale.riskAndDisclaimer.description')}
      </Typography>
      <TwoColumnText {...riskDisclaimerProps} />
    </Container>
  );
};

export default RiskDisclaimer;
