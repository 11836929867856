import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Typography from 'components/Typography';
import useIsMobile from 'hooks/useIsMobile';

import styles from './styles.module.css';

const percentToDegrees = (value) => {
  return (value * 360) / 100;
};

const PieSlice = ({
  percent,
  color,
  hoverColor,
  rotate,
  onMouseOver,
  onMouseLeave,
  activeFlag,
}) => {
  const formula = `calc(${percent} * 31.42 / 100) 31.42`;
  const rotateTransform = `rotate(${rotate - 90})`;

  return (
    <circle
      r="5"
      cx="10"
      cy="10"
      stroke={activeFlag ? hoverColor : color}
      strokeWidth="10"
      transform={rotateTransform}
      className={`${styles.circle} ${activeFlag && styles.opacity}`}
      strokeDasharray={formula}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    />
  );
};

const Chart = ({ dataChart }) => {
  const [active, setActive] = useState(0);
  const [highlighted, setHightlighted] = useState(dataChart[0]);

  const isMobile = useIsMobile();

  const getDegrees = (index) => {
    let deg = 0;
    for (let i = 1; i <= index; i++) {
      deg += dataChart[i - 1]?.value;
    }
    return deg;
  };

  return (
    <Container style={{ position: 'relative' }}>
      <Row>
        <Col xs={12} md={{ offset: 1, span: 4 }}>
          <div className={styles.pieBox}>
            {!!highlighted?.value && (
              <div className={styles.highlight} style={{ background: highlighted?.color }}>
                <Typography variant="body1"> {highlighted?.text}</Typography>
                <Typography variant="h2" translate="no">
                  {highlighted?.value?.toFixed(1)}%
                </Typography>
              </div>
            )}
            <svg
              className={styles.mainSlices}
              height={isMobile ? 300 : 400}
              width={isMobile ? 300 : 400}
              viewBox="0 0 20 20"
            >
              {dataChart?.map((slice, index) => {
                const deg = getDegrees(index);
                return (
                  <PieSlice
                    key={index}
                    percent={slice?.value}
                    color={slice?.color}
                    hoverColor={slice?.color}
                    rotate={index === 0 ? 0 : percentToDegrees(deg)}
                    activeFlag={active === index}
                    onMouseOver={() => {
                      setActive(index);
                      setHightlighted(slice);
                    }}
                  />
                );
              })}
            </svg>
            <svg
              className={styles.blackSlices}
              height={isMobile ? 220 : 270}
              width={isMobile ? 300 : 400}
              viewBox="0 0 20 20"
            >
              <defs />
              {dataChart?.map((slice, index) => {
                const deg = getDegrees(index);
                return (
                  <PieSlice
                    key={index}
                    percent={slice?.value}
                    color="var(--grayBlue)"
                    rotate={index === 0 ? 0 : percentToDegrees(deg)}
                    activeFlag={active === index}
                    onMouseOver={() => {
                      setActive(index);
                      setHightlighted(slice);
                    }}
                  />
                );
              })}
            </svg>
          </div>
        </Col>
        <Col xs={12} md={{ offset: 1, span: 4 }} className="my-auto">
          <div className={styles.chartInfo}>
            {dataChart?.map((row, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.infoRow} ${active === index && styles.active}`}
                  onMouseOver={() => {
                    setActive(index);
                    setHightlighted(dataChart[index]);
                  }}
                >
                  <Typography variant="body3" align="left" className="me-2">
                    {row?.text}
                  </Typography>
                  <Typography variant="body3" translate="no">
                    {row?.value?.toFixed(1)}%
                  </Typography>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Chart;
