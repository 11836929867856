import { useState, useEffect } from 'react';
import useWindowSize from './useWindowSize';

const useIsMobile = (breakpoint = 768) => {
  const { width } = useWindowSize();
  const [isMobile, setMobile] = useState(width <= (breakpoint || 768));

  useEffect(() => {
    setMobile(width <= (breakpoint || 768));
  }, [width, breakpoint]);

  return isMobile;
};

export default useIsMobile;
