import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Typography from 'components/Typography';
import SuspenseImg from 'components/SuspenseImage';

import styles from './styles.module.css';

const DescriptiveBox = (props) => {
  const { data } = props;

  return (
    <Container>
      <Row className={styles.titleBox}>
        <Col className="text-center">
          <Typography variant="caption" color="gradient">
            {data.pretitle}
          </Typography>
          <Typography variant="h1" className="justify-content-center">
            {data.title}
          </Typography>
          <Typography color="blueGray" className={`justify-content-center ${styles.description}`}>
            {data.description}
          </Typography>
        </Col>
      </Row>
      <div className={styles.box}>
        <Row>
          {data.items?.map((item, index) => (
            <Col
              key={index}
              lg={{ offset: (index % 2) + 1, span: 4 }}
              className="mb-5 text-center text-lg-start"
            >
              <SuspenseImg src={item.image} className="mb-3" />
              <Typography variant="body2" className="mb-3 text-lg-start">
                {item.title}
              </Typography>
              <Typography color="blueGray" className="text-lg-start">
                {item.description}
              </Typography>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
};

DescriptiveBox.propTypes = {
  data: PropTypes.shape({
    pretitle: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
};

export default DescriptiveBox;
