import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';

import { translate } from 'translations/i18n';
import Typography from 'components/Typography';
import iphoneOff from 'assets/images/offIphone.webp';
import iphoneOn from 'assets/images/onIphone.webp';
import SuspenseImg from 'components/SuspenseImage';

import styles from './styles.module.css';

const IntroducingClapArt = () => {
  const turnOnThePhone = () => {
    document.getElementById('turnedOnIphone').style.opacity = 1;
  };

  const turnOffThePhone = () => {
    document.getElementById('turnedOnIphone').style.opacity = 0;
  };

  return (
    <Container className={styles.wrapper} id="introducing-clapart-section">
      <Row className="align-items-center gap-4 gap-sm-0">
        <Col sm={7} className="order-1 order-sm-0 text-center text-sm-start mt-2">
          <Typography variant="label" color="gradient" className="text-center text-sm-start mb-3">
            {translate('introClapart.pretitle')}
          </Typography>
          <Typography variant="h1" className="text-center text-sm-start mb-3">
            {translate('introClapart.title')}
          </Typography>
          <Typography color="blueGray" className="text-center text-sm-start mb-3">
            {translate('introClapart.text')}
          </Typography>
          <Button variant="secondary" disabled className="m-auto m-sm-0">
            {translate('introClapart.download')}
          </Button>
        </Col>
        <Col sm={5} className="text-center text-sm-end">
          <motion.div
            onViewportEnter={turnOnThePhone}
            onViewportLeave={turnOffThePhone}
            className={styles.iphoneWrapper}
          >
            <SuspenseImg src={iphoneOff} className={styles.turnedOffIphone} />
            <SuspenseImg src={iphoneOn} className={styles.turnedOnIphone} id="turnedOnIphone" />
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default IntroducingClapArt;
