import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import Typography from '../Typography';
import externalLink from 'assets/icons/externalLink.svg';

import styles from 'components/PresalesCards/styles.module.css';

const PresalesCards = ({ content }) => {
  return (
    <div className={styles.presalesCardsContainer}>
      {content.map((card, index) => (
        <div
          key={index}
          className={styles.presalesCard}
          style={{
            backgroundColor: `rgba(169, 126, 255, ${0.9 - index * 0.2})`,
            cursor: card.link ? 'pointer' : 'default',
          }}
          onClick={() => {
            card.link && window.open(card.link, '_blank');
          }}
        >
          <Typography variant="body4">{card.title}</Typography>
          {card.content ? (
            <Typography variant="body2" className="d-flex align-items-center gap-2">
              {card.content}
              {card.externalLinkIcon && (
                <Image src={externalLink} className={styles.externalLinkIcon} />
              )}
            </Typography>
          ) : (
            <img src={card.image} alt="image_alt" />
          )}
        </div>
      ))}
    </div>
  );
};

PresalesCards.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string,
      image: PropTypes.string,
    }),
  ).isRequired,
};

export default PresalesCards;
