import certikImg from 'assets/images/presalesCardComponents/certik-img.svg';
import certikCertification from 'assets/CertikCertification.pdf';

import { translate } from 'translations/i18n';

export const presalesCardsContent = [
  {
    title: translate('presalesCards.smartContractAdress.text1'),
    content: translate('presalesCards.smartContractAdress.text2'),
    link: 'https://bscscan.com/token/0x5e61e8e2aadb381af29c3048671b2d4477764ada',
    externalLinkIcon: true,
  },
  {
    title: translate('presalesCards.totalSupply.text1'),
    content: translate('presalesCards.totalSupply.text2'),
  },
  {
    title: translate('presalesCards.auditedBy.text1'),
    link: certikCertification,
    image: certikImg,
  },
  {
    title: translate('presalesCards.blockchain.text1'),
    content: translate('presalesCards.blockchain.text2'),
  },
];
