import box from 'assets/icons/box.svg';
import gift from 'assets/icons/gift.svg';
import puzzle from 'assets/icons/puzzle.svg';
import deflationaryTokenomics from 'assets/icons/deflationaryTokenomics.svg';
import { translate } from 'translations/i18n';

const benefitsProps = {
  items: [
    {
      title: translate('presale.benefits.benefit1'),
      description: translate('presale.benefits.benefit1Details'),
      image: box,
    },
    {
      title: translate('presale.benefits.benefit2'),
      description: translate('presale.benefits.benefit2Details'),
      image: gift,
    },
    {
      title: translate('presale.benefits.benefit3'),
      description: translate('presale.benefits.benefit3Details'),
      image: puzzle,
    },
    {
      title: translate('presale.benefits.benefit4'),
      description: translate('presale.benefits.benefit4Details'),
      image: deflationaryTokenomics,
    },
  ],
  pretitle: translate('presale.benefits.pretitle'),
  title: translate('presale.benefits.title'),
  description: translate('presale.benefits.description'),
};

export default benefitsProps;
