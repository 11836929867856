import axios from 'axios';

const baseUrl = process.env.REACT_APP_STORYBLOK_URL;
const token = process.env.REACT_APP_STORYBLOK_KEY;
const version = process.env.REACT_APP_STORYBLOK_VERSION;
const whitepapersUrl = `${baseUrl}?token=${token}&version=${version}&by_slugs=whitepapers/*`;

const getNameByLanguageKey = (languageKey) => {
  switch (languageKey) {
    case 'en-US':
      return 'English';
    case 'ro':
      return 'Romania';
    case 'es-ES':
      return 'Spain';
    case 'tr':
      return 'Turkey';
    default:
      return 'English';
  }
};

const getWhitepaper = async () => {
  try {
    const whitepapers = await axios.get(whitepapersUrl);
    const languageKey = localStorage.getItem('mw-active-current-locale');

    const languageName = getNameByLanguageKey(languageKey);

    const {
      data: { stories },
    } = whitepapers;

    const selectedStory = stories?.find((st) => st?.name === languageName);

    const file = selectedStory?.content?.file?.filename;

    return file;
  } catch (e) {
    throw new Error(e);
  }
};

export default getWhitepaper;
