import React from 'react';
import { PropTypes } from 'prop-types';

import useIsMobile from 'hooks/useIsMobile';

const GradientBG = ({
  deskWidth,
  mobWidth,
  deskHeight,
  mobHeight,
  top,
  left,
  opacity,
  blur,
  deg,
  radius,
  zIndex,
}) => {
  const isMobile = useIsMobile();

  const style = {
    position: 'absolute',
    width: isMobile ? `${mobWidth}%` : `${deskWidth}%`,
    height: isMobile ? `${mobHeight}%` : `${deskHeight}%`,
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-50%, -50%)`,
    background: `linear-gradient(${deg}deg, #5BAEFF 18.46%, #5BAEFF 41.72%, #9B4BF2 63.96%, #FF29C3 83.39%)`,
    opacity: opacity,
    filter: `blur(${blur}px)`,
    borderRadius: `${radius}%`,
    zIndex,
  };

  return <div style={style}></div>;
};

GradientBG.propTypes = {
  gradient: PropTypes.string,
  deskWidth: PropTypes.number,
  mobWidth: PropTypes.number,
  deskHeight: PropTypes.number,
  mobHeight: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
  opacity: PropTypes.number,
  blur: PropTypes.number,
  deg: PropTypes.number,
  radius: PropTypes.number,
  zIndex: PropTypes.number,
};

GradientBG.defaultProps = {
  deskWidth: 90,
  mobWidth: 90,
  deskHeight: 50,
  mobHeight: 90,
  top: 50,
  left: 50,
  opacity: 0.4,
  blur: 100,
  deg: 30,
  radius: 50,
  zIndex: -1,
};

export default GradientBG;
