import { translate } from 'translations/i18n';
import royaltyShareImg from 'assets/images/royaltyShareImg.svg';

const theRoyaltyShareData = {
  image: royaltyShareImg,
  title: translate('royaltyShare.title'),
  text: translate('royaltyShare.text'),
};

export default theRoyaltyShareData;
