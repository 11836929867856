import { useState, useCallback, useMemo, useEffect } from 'react';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_STORYBLOK_URL;
const token = process.env.REACT_APP_STORYBLOK_KEY;
const version = process.env.REACT_APP_STORYBLOK_VERSION;
const categoriesUrl = `${baseUrl}?token=${token}&version=${version}&by_slugs=article-categories/*`;

const featuredQuery = `&filter_query[featured][is]=true`;
const latestQuery = (pageNr, articlesPerPage, articlesOrder) =>
  `&page=${pageNr}&per_page=${articlesPerPage}&sort_by=created_at:${articlesOrder}`;
const byCategoryQuery = (categoryUid) => `&filter_query[category][in]=${categoryUid}`;

export const ARTICLES_BY = {
  LATEST: 'latest',
  FEATURED: 'featured',
  CATEGORY: 'category',
};

const fetchBy = ({ by, category, pageNr, articlesPerPage, articlesOrder }) => {
  switch (by) {
    case ARTICLES_BY.FEATURED:
      return featuredQuery;
    case ARTICLES_BY.LATEST:
      return latestQuery(pageNr, articlesPerPage, articlesOrder);
    case ARTICLES_BY.CATEGORY:
      return byCategoryQuery(category);
    default:
      return '';
  }
};

const mapCategories = (categories) =>
  categories?.map((c) => {
    return {
      ...c,
    };
  });

const mapStoriesToEntries = (stories) =>
  stories?.map((st) => {
    return {
      ...st?.content,
      slug: st?.full_slug,
    };
  });

const useBlog = () => {
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const categoryQueryParam = useMemo(
    () => (activeCategory?.uuid ? `&filter_query[category][in]=${activeCategory?.uuid}` : ''),
    [activeCategory],
  );

  const searchQueryParam = useMemo(
    () => (searchQuery ? `&filter_query[title][like]=*${searchQuery}*` : ''),
    [searchQuery],
  );

  const fetchById = async ({ id }) => {
    try {
      const postUrl = `${baseUrl}/${id}?token=${token}&find_by=uuid`;

      const res = await axios.get(postUrl);
      const {
        data: { story },
      } = res;
      return story;
    } catch (err) {
      throw new Error(err);
    }
  };

  const fetchPosts = async (type) => {
    try {
      const postsUrl = `${baseUrl}?token=${token}&version=${version}&by_slugs=${type}/*&excluding_slugs=${type}/${categoryQueryParam}${searchQueryParam}&page=1&per_page=100`;

      const res = await axios.get(postsUrl);
      const {
        data: { stories },
      } = res;
      const mappedStories = mapStoriesToEntries(stories);
      return mappedStories;
    } catch (err) {
      throw new Error(err);
    }
  };

  const fetchArticles = async ({
    by = ARTICLES_BY.LATEST,
    pageNr = 1,
    category,
    articlesPerPage = 3,
    articlesOrder = 'desc',
  }) => {
    try {
      const fetchByType = fetchBy({ by, pageNr, articlesPerPage, articlesOrder, category });
      const postsUrl = `${baseUrl}?token=${token}&version=${version}&by_slugs=articles/*&excluding_slugs=articles/${fetchByType}`;

      const res = await axios.get(postsUrl);
      const {
        data: { stories },
      } = res;
      return stories;
    } catch (err) {
      throw new Error(err);
    }
  };

  const fetchCategories = useCallback(async () => {
    try {
      const res = await axios.get(categoriesUrl);
      const {
        data: { stories },
      } = res;
      const mappedCategories = mapCategories(stories);
      const allCategory = { name: 'All' };
      const allCategories = [allCategory, ...mappedCategories];
      setCategories(allCategories);
      return;
    } catch (err) {
      throw new Error(err);
    }
  }, []);

  const getCategoryByUuid = (uuid) => categories?.find((c) => c?.uuid === uuid);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return {
    categories,
    fetchPosts,
    searchQuery,
    setSearchQuery,
    activeCategory,
    setActiveCategory,
    getCategoryByUuid,
    fetchArticles,
    fetchById,
  };
};

export default useBlog;
