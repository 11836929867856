import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { storyblokInit, apiPlugin } from '@storyblok/react';

import StoryblokApp from 'StoryblokApp';
import { BlogProvider } from 'context/Blog.context';
import reportWebVitals from './reportWebVitals';
import KillTweens from 'helpers/KillTweens';

import './index.css';

storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_KEY,
  use: [apiPlugin],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <KillTweens>
        <BlogProvider>
          <StoryblokApp />
        </BlogProvider>
      </KillTweens>
    </Router>
  </React.StrictMode>,
);

reportWebVitals();
