import React, { useState } from 'react';
import Wave from 'react-wavify';
import { motion } from 'framer-motion';

import styles from './styles.module.css';

const AnimatedWaves = () => {
  const waveSpeed = 0.3;
  const [pauseAnimation, setPauseAnimation] = useState(false);

  const startWaveAnimation = () => {
    setPauseAnimation(false);
  };

  const pauseWaveAnimation = () => {
    setPauseAnimation(true);
  };

  return (
    <motion.div
      onViewportEnter={() => {
        startWaveAnimation();
        setTimeout(() => {
          pauseWaveAnimation();
        }, 2000);
      }}
      className={styles.waveContainer}
      onMouseEnter={() => startWaveAnimation()}
      onMouseLeave={() => pauseWaveAnimation()}
    >
      {/* wave --------- 4 SHADOW */}
      <Wave
        className={styles.wave}
        fill="url(#shadow4)"
        paused={pauseAnimation}
        options={{
          height: 70,
          amplitude: 20,
          speed: waveSpeed,
          points: 11,
        }}
      >
        <defs>
          <linearGradient id="shadow4" gradientTransform="rotate(90)">
            <stop offset="100%" stopColor="#45378B80" />
            <stop offset="50%" stopColor="#6839A3" />
          </linearGradient>
        </defs>
      </Wave>
      {/* wave --------- 4 */}
      <Wave
        className={styles.wave}
        fill="url(#wave4)"
        paused={pauseAnimation}
        options={{
          height: 80,
          amplitude: 20,
          speed: waveSpeed,
          points: 11,
        }}
      >
        <defs>
          <linearGradient id="wave4" gradientTransform="rotate(90)">
            <stop offset="10%" stopColor="#45378B90" />
            <stop offset="50%" stopColor="#6839A3" />
          </linearGradient>
        </defs>
      </Wave>
      {/* wave --------- 3 SHADOW */}
      <Wave
        className={styles.wave}
        fill="url(#shadow3)"
        paused={pauseAnimation}
        options={{
          height: 110,
          amplitude: 30,
          speed: waveSpeed,
          points: 10,
        }}
      >
        <defs>
          <linearGradient id="shadow3" gradientTransform="rotate(90)">
            <stop offset="10%" stopColor="#E757FF80" />
            <stop offset="50%" stopColor="#0e072600" />
          </linearGradient>
        </defs>
      </Wave>
      {/* wave --------- 3 */}
      <Wave
        className={styles.wave}
        fill="url(#wave3)"
        paused={pauseAnimation}
        options={{
          height: 120,
          amplitude: 30,
          speed: waveSpeed,
          points: 10,
        }}
      >
        <defs>
          <linearGradient id="wave3" gradientTransform="rotate(90)">
            <stop offset="10%" stopColor="#E757FF90" />
            <stop offset="50%" stopColor="#0e072600" />
          </linearGradient>
        </defs>
      </Wave>
      {/* wave --------- 2 SHADOW */}
      <Wave
        className={styles.wave}
        fill="url(#shadow2)"
        paused={pauseAnimation}
        options={{
          height: 150,
          amplitude: 30,
          speed: waveSpeed,
          points: 7,
        }}
      >
        <defs>
          <linearGradient id="shadow2" gradientTransform="rotate(90)">
            <stop offset="10%" stopColor="#5AAEFF80" />
            <stop offset="90%" stopColor="#E757FF00" />
          </linearGradient>
        </defs>
      </Wave>
      {/* wave --------- 2 */}
      <Wave
        className={styles.wave}
        fill="url(#wave2)"
        paused={pauseAnimation}
        options={{
          height: 160,
          amplitude: 30,
          speed: waveSpeed,
          points: 7,
        }}
      >
        <defs>
          <linearGradient id="wave2" gradientTransform="rotate(90)">
            <stop offset="10%" stopColor="#5AAEFF90" />
            <stop offset="90%" stopColor="#E757FF90" />
          </linearGradient>
        </defs>
      </Wave>
      {/* wave --------- 1 SHADOW */}
      <Wave
        className={styles.wave}
        fill="url(#shadow1)"
        paused={pauseAnimation}
        options={{
          height: 220,
          amplitude: 20,
          speed: waveSpeed,
          points: 3,
        }}
      >
        <defs>
          <linearGradient id="shadow1" gradientTransform="rotate(90)">
            <stop offset="10%" stopColor="#0e072620" />
            <stop offset="90%" stopColor="#0e0726" />
          </linearGradient>
        </defs>
      </Wave>
      {/* wave --------- 1 */}
      <Wave
        className={styles.wave}
        fill="url(#wave1)"
        paused={pauseAnimation}
        options={{
          height: 230,
          amplitude: 20,
          speed: waveSpeed,
          points: 3,
        }}
      >
        <defs>
          <linearGradient id="wave1" gradientTransform="rotate(90)">
            <stop offset="10%" stopColor="#0e0726" />
            <stop offset="90%" stopColor="#0e0726" />
          </linearGradient>
        </defs>
      </Wave>
    </motion.div>
  );
};

export default AnimatedWaves;
