import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import logoColor from 'assets/images/logoColor.svg';
import logoWhite from 'assets/images/logo.svg';
import menuIcon from 'assets/icons/menuIcon.svg';
import { translate } from 'translations/i18n';
import Typography from 'components/Typography';
import Menu from 'components/Menu';
import SuspenseImg from 'components/SuspenseImage';
import LanguageSwitcher from '../LanguageSwitcher';

import styles from './styles.module.css';

const Header = ({ refProp, sticky, theme }) => {
  const [menuOpened, setMenuOpened] = useState(false);

  const navigate = useNavigate();

  const handleOpenMenu = () => {
    setMenuOpened(true);
  };

  const handleCloseMenu = () => {
    setMenuOpened(false);
  };

  return (
    <>
      <div
        ref={refProp}
        id="headerWrapper"
        className={`${styles.wrapper} ${theme === 'dark' ? styles.darkWrapper : ''} ${
          sticky && styles.sticky
        }`}
      >
        <Container id="headerContainer">
          <Row className={styles.headerRow}>
            <Col className="text-start" xs={5}>
              <SuspenseImg
                src={theme === 'dark' ? logoWhite : logoColor}
                onClick={() => {
                  navigate('/');
                }}
                className="pointer"
              />
            </Col>
            <Col className="text-end" xs={7}>
              <div className={styles.optionsContainer}>
                <LanguageSwitcher theme={theme} />
                <div className={styles.menu} onClick={handleOpenMenu}>
                  <Typography
                    align="left"
                    color={theme === 'light' ? 'black' : 'white'}
                    className="mx-auto"
                    variant="body3"
                  >
                    {translate('header.menu')}
                  </Typography>
                  <SuspenseImg src={menuIcon} className={styles.menuIcon} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Menu handleClose={handleCloseMenu} opened={menuOpened} theme={theme} />
    </>
  );
};

Header.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};

export default Header;
