import scrollIntoView from 'scroll-into-view';

const scrollToSection = (id) => {
  scrollIntoView(document.getElementById(id), {
    time: 500,
    align: {
      top: 0,
      topOffset: 90,
    },
    cancellable: false,
  });
};

export default scrollToSection;
