import { translate } from 'translations/i18n';
import problem from 'assets/icons/problem.svg';

const theProblems = {
  problems: [
    {
      title: translate('theProblem.firstTitle'),
      description: translate('theProblem.firstText'),
      solution: translate('theProblem.firstSolution'),
      image: problem,
    },
    {
      title: translate('theProblem.secondTitle'),
      description: translate('theProblem.secondText'),
      solution: translate('theProblem.secondSolution'),
      image: problem,
    },
    {
      title: translate('theProblem.thirdTitle'),
      description: translate('theProblem.thirdText'),
      solution: translate('theProblem.thirdSolution'),
      image: problem,
    },
  ],
  secondaryTitle: translate('theProblem.clapartSolution'),
  pretitle: translate('theProblem.pretitle'),
  title: translate('theProblem.title'),
  subtitle: translate('theProblem.subtitle'),
};

export default theProblems;
