import React from 'react';
import PropTypes from 'prop-types';

import useIsMobile from 'hooks/useIsMobile';

const SectionSpacing = ({ height }) => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{ height: `${isMobile ? height / 2.5 : height}px`, backgroundColor: 'transparent' }}
    />
  );
};

SectionSpacing.propTypes = {
  height: PropTypes.number,
};

SectionSpacing.defaultProps = {
  height: 175,
};

export default SectionSpacing;
